module.exports = {
    'FIRST NAME': '',
    'LAST NAME': '',
    'DATE OF BIRTH': '',
    'GENDER': '',
    'HEALTH CARD NUMBER': '',
    'UNIT NUMBER': '',
    'STREET NUMBER': '',
    'STREET NAME': '',
    'PO BOX': '',
    'CITY\/TOWN': '',
    'PROVINCE': '',
    'POSTAL CODE': '',
    'TELEPHONE NUMBER': '',
    'EMAIL ADDRESS': '',
    'CAREGIVER FIRST NAME': '',
    'CAREGIVER LAST NAME': '',
    'CAREGIVER PHONE #': '',
    'CARGIVER EMAIL ADDRESS': '',
    'CAREGIVER NOTES': '',
    'Check Box9': '',
    'LIST OF ALLERGIES': '',
    'OCCUPATION': '',
    'PRIVATE INSURANCE YES': '',
    'PRIVATE INSURANCE NO': '',
    'tobacco': '',
    'tobacco yes': '',
    'tobacco no': '',
    '# of cigs': '',
    'smoking cessation status': '',
    'smoking cessation status dropdown': '',
    'Recreational Drug Use': '',
    'Recreational Drug Use yes': '',
    'Recreational Drug Use no': '',
    'Recreational Drug Use Notes': '',
    'alcohol use': '',
    'alcohol use yes': '',
    'alcohol use no': '',
    'Alcohol use Dropdown': '',
    'Exercise Regimen': '',
    'MINUTES PER WEEK': '',
    'EXERCISE REGIMEN NOTES': '',
    'lifestyle information other': '',
    'LIFESTYLE INFORMATION OTHER NOTES': '',
    'LIFESTYLE RECCOMENDATIONS DROPDOWN': '',
    'LIFESTYLE RECCOMENDATIONS DROPDOWN1': '',
    'LIFESTYLE RECCOMENDATIONS DROPDOWN2': '',
    'LIFESTYLE RECCOMENDATIONS': '',
    'PRIMARY FIRST NAME': '',
    'PRIMARY LAST NAME': '',
    'PROVIDER DESIGNATION': '',
    'PRIMARY PHONE #': '',
    'PRIMARY FAX #': '',
    'PRIMARY EMAIL ADDRESS': '',
    'health care providers with who to communicate': '',
    'Check Box7': '',
    'MED 1': '',
    'INSTRUCITONS 1': '',
    'MED 1 STR': '',
    'MED 1 FORM': '',
    'MED 1 AM QTY': '',
    'MED 1 LUNCH QTY': '',
    'MED 1 DINNER QTY': '',
    'MED 1 BEDTIME QTY': '',
    'MED 1 OTHER QTY': '',
    'ADHERENCE YES 1': '',
    'ADHERENCE NO 1': '',
    'PATIENT COMMENT 1': '',
    'COMMENTS FOR MEDSCHECK RECORD 1': '',
    'RX?1': '',
    'OTC?1': '',
    'NHP?1': '',
    'MED 1 PHARMACIST NOTES': '',
    'MED 2': '',
    'INSTRUCITONS 1.1': '',
    'MED 2 STRENGTH AND FORM': '',
    'MED 2 AM QTY': '',
    'MED 2 LUNCH QTY': '',
    'MED 2 DINNER QTY': '',
    'MED 2 BEDTIME QTY': '',
    'MED 2 OTHER QTY': '',
    'ADHERENCE YES 2': '',
    'ADHERENCE NO 2': '',
    'PATIENT COMMENTS.1': '',
    'COMMENTS MED 1.1': '',
    'RX?2': '',
    'OTC?2': '',
    'NHP?2': '',
    'MED 2 PHARMACIST NOTES': '',
    'MED 3': '',
    'INSTRUCITONS 1.2': '',
    'MED 3 STRENGTH AND FORM': '',
    'MED 3 AM QTY': '',
    'MED 3 LUNCH QTY': '',
    'MED 3 DINNER QTY': '',
    'MED 3 BEDTIME QTY': '',
    'MED 3 OTHER QTY': '',
    'ADHERENCE YES 3': '',
    'ADHERENCE NO 3': '',
    'PATIENT COMMENTS.2': '',
    'COMMENTS MED 1.2': '',
    'RX?3': '',
    'OTC?3': '',
    'NHP?3': '',
    'MED 3 PHARMACIST NOTES': '',
    'MED 4': '',
    'INSTRUCITONS 1.3': '',
    'MED 4 STRENGTH AND FORM': '',
    'MED 4 AM QTY': '',
    'MED 4 LUNCH QTY': '',
    'MED 4 DINNER QTY': '',
    'MED 4 BEDTIME QTY': '',
    'MED 4 OTHER QTY': '',
    'ADHERENCE YES 4': '',
    'ADHERENCE NO 4': '',
    'PATIENT COMMENTS.3': '',
    'COMMENTS MED 1.3': '',
    'RX?4': '',
    'OTC?4': '',
    'NHP?4': '',
    'MED 4 PHARMACIST NOTES': '',
    'MED 5': '',
    'INSTRUCITONS 1.4': '',
    'MED 5 STRENGTH AND FORM': '',
    'MED 5 AM QTY': '',
    'MED 5 LUNCH QTY': '',
    'MED 5 DINNER QTY': '',
    'MED 5 BEDTIME QTY': '',
    'MED 5 OTHER QTY': '',
    'ADHERENCE YES 5': '',
    'ADHERENCE NO 5': '',
    'PATIENT COMMENTS.4': '',
    'COMMENTS MED 1.4': '',
    'RX?5': '',
    'OTC?5': '',
    'NHP?5': '',
    'MED 5 PHARMACIST NOTES': '',
    'MED 6': '',
    'INSTRUCITONS 1.5': '',
    'MED 6 STRENGTH AND FORM': '',
    'MED 6 AM QTY': '',
    'MED 6 LUNCH QTY': '',
    'MED 6 DINNER QTY': '',
    'MED 6 BEDTIME QTY': '',
    'MED 6 OTHER QTY': '',
    'ADHERENCE YES 6': '',
    'ADHERENCE NO 6': '',
    'PATIENT COMMENTS.5': '',
    'COMMENTS MED 1.5': '',
    'RX?6': '',
    'OTC?6': '',
    'NHP?6': '',
    'MED 6 PHARMACIST NOTES': '',
    'MED 7': '',
    'INSTRUCITONS 1.6': '',
    'MED 7 STRENGTH AND FORM': '',
    'MED 7 AM QTY': '',
    'MED 7 LUNCH QTY': '',
    'MED 7 DINNER QTY': '',
    'MED 7 BEDTIME QTY': '',
    'MED 7 OTHER QTY': '',
    'ADHERENCE YES 7': '',
    'ADHERENCE NO 7': '',
    'PATIENT COMMENTS.6': '',
    'COMMENTS MED 1.6': '',
    'RX?7': '',
    'OTC?7': '',
    'NHP?7': '',
    'MED 7 PHARMACIST NOTES': '',
    'MED 8': '',
    'INSTRUCITONS 1.7': '',
    'MED 8 STRENGTH AND FORM': '',
    'MED 8 AM QTY': '',
    'MED 8 LUNCH QTY': '',
    'MED 8 DINNER QTY': '',
    'MED 8 BEDTIME QTY': '',
    'MED 8 OTHER QTY': '',
    'ADHERENCE YES 8': '',
    'ADHERENCE NO 8': '',
    'PATIENT COMMENTS.7': '',
    'COMMENTS MED 1.7': '',
    'RX?8': '',
    'OTC?8': '',
    'NHP?8': '',
    'MED 8 PHARMACIST NOTES': '',
    'MED 9': '',
    'INSTRUCITONS 1.8': '',
    'MED 9 STRENGTH AND FORM': '',
    'MED 9 AM QTY': '',
    'MED 9 LUNCH QTY': '',
    'MED 9 DINNER QTY': '',
    'MED 9 BEDTIME QTY': '',
    'MED 9 OTHER QTY': '',
    'PATIENT COMMENTS.8': '',
    'COMMENTS MED 1.8': '',
    'RX?9': '',
    'OTC?9': '',
    'NHP?9': '',
    'MED 9 PHARMACIST NOTES': '',
    'MED 10': '',
    'INSTRUCITONS 1.9': '',
    'MED 10 STRENGTH AND FORM': '',
    'MED 10 AM QTY': '',
    'MED 10 LUNCH QTY': '',
    'MED 10 DINNER QTY': '',
    'MED 10 BEDTIME QTY': '',
    'MED 10 OTHER QTY': '',
    'PATIENT COMMENTS.9': '',
    'COMMENTS MED 1.9': '',
    'RX?10': '',
    'OTC?10': '',
    'NHP?10': '',
    'MED 10 PHARMACIST NOTES': '',
    'MED 11': '',
    'INSTRUCITONS 1.10A': '',
    'MED 11 STRENGTH AND FORM': '',
    'MED 11 AM QTY': '',
    'MED 11 LUNCH QTY': '',
    'MED 11 DINNER QTY': '',
    'MED 11 BEDTIME QTY': '',
    'MED 11 OTHER QTY': '',
    'ADHERENCE YES 11': '',
    'ADHERENCE NO 11': '',
    'PATIENT COMMENTS.10A': '',
    'COMMENTS MED 1.10A': '',
    'RX?11': '',
    'OTC?11': '',
    'NHP?11': '',
    'MED 11 PHARMACIST NOTES': '',
    'MED 12': '',
    'INSTRUCITONS 1.11': '',
    'MED 12 STRENGTH AND FORM': '',
    'MED 12 AM QTY': '',
    'MED 12 LUNCH QTY': '',
    'MED 12 DINNER QTY': '',
    'MED 12 BEDTIME QTY': '',
    'MED 12 OTHER QTY': '',
    'ADHERENCE YES 12': '',
    'ADHERENCE NO 12': '',
    'PATIENT COMMENTS.11': '',
    'COMMENTS MED 1.11': '',
    'RX?12': '',
    'OTC?12': '',
    'NHP?12': '',
    'MED 12 PHARMACIST NOTES': '',
    'MED 13': '',
    'INSTRUCITONS 1.12': '',
    'MED 13 STRENGTH AND FORM': '',
    'MED 13 AM QTY': '',
    'MED 13 LUNCH QTY': '',
    'MED 13 DINNER QTY': '',
    'MED 13 BEDTIME QTY': '',
    'MED 13 OTHER QTY': '',
    'ADHERENCE YES 13': '',
    'ADHERENCE NO 13': '',
    'PATIENT COMMENTS.12': '',
    'COMMENTS MED 1.12': '',
    'RX?13': '',
    'OTC?13': '',
    'NHP?13': '',
    'MED 13 PHARMACIST NOTES': '',
    'MED 14': '',
    'INSTRUCITONS 1.13': '',
    'MED 14 STRENGTH AND FORM': '',
    'MED 14 AM QTY': '',
    'MED 14 LUNCH QTY': '',
    'MED 14 DINNER QTY': '',
    'MED 14 BEDTIME QTY': '',
    'MED 14 OTHER QTY': '',
    'ADHERENCE YES 14': '',
    'ADHERENCE NO 14': '',
    'PATIENT COMMENTS.13': '',
    'COMMENTS MED 1.13': '',
    'RX?14': '',
    'OTC?14': '',
    'NHP?14': '',
    'MED 14 PHARMACIST NOTES': '',
    'MED 15': '',
    'INSTRUCITONS 1.14': '',
    'MED 15 STRENGTH AND FORM': '',
    'MED 15 AM QTY': '',
    'MED 15 LUNCH QTY': '',
    'MED 15 DINNER QTY': '',
    'MED 15 BEDTIME QTY': '',
    'MED 15 OTHER QTY': '',
    'ADHERENCE YES 15': '',
    'ADHERENCE NO 15': '',
    'PATIENT COMMENTS.14': '',
    'COMMENTS MED 1.14': '',
    'RX?15': '',
    'OTC?15': '',
    'NHP?15': '',
    'MED 15 PHARMACIST NOTES': '',
    'MED 16': '',
    'INSTRUCITONS 1.15': '',
    'MED 16 STRENGTH AND FORM': '',
    'MED 16 AM QTY': '',
    'MED 16 LUNCH QTY': '',
    'MED 16 DINNER QTY': '',
    'MED 16 BEDTIME QTY': '',
    'MED 16 OTHER QTY': '',
    'ADHERENCE YES 16': '',
    'ADHERENCE NO 16': '',
    'PATIENT COMMENTS.15': '',
    'COMMENTS MED 1.15': '',
    'RX?16': '',
    'OTC?16': '',
    'NHP?16': '',
    'MED 16 PHARMACIST NOTES': '',
    'MED 17': '',
    'INSTRUCITONS 1.16': '',
    'MED 17 STRENGTH AND FORM': '',
    'MED 17 AM QTY': '',
    'MED 17 LUNCH QTY': '',
    'MED 17 DINNER QTY': '',
    'MED 17 BEDTIME QTY': '',
    'MED 17 OTHER QTY': '',
    'ADHERENCE YES 17': '',
    'ADHERENCE NO 17': '',
    'PATIENT COMMENTS.16': '',
    'COMMENTS MED 1.16': '',
    'RX?17': '',
    'OTC?17': '',
    'NHP?17': '',
    'MED 17 PHARMACIST NOTES': '',
    'MED 18': '',
    'INSTRUCITONS 1.17': '',
    'MED 18 STRENGTH AND FORM': '',
    'MED 18 AM QTY': '',
    'MED 18 LUNCH QTY': '',
    'MED 18 DINNER QTY': '',
    'MED 18 BEDTIME QTY': '',
    'MED 18 OTHER QTY': '',
    'ADHERENCE YES 18': '',
    'ADHERENCE NO 18': '',
    'PATIENT COMMENTS.17': '',
    'COMMENTS MED 1.17': '',
    'RX?18': '',
    'OTC?18': '',
    'NHP?18': '',
    'MED 18 PHARMACIST NOTES': '',
    'MED 19': '',
    'INSTRUCITONS 1.18': '',
    'MED 19 STRENGTH AND FORM': '',
    'MED 19 AM QTY': '',
    'MED 19 LUNCH QTY': '',
    'MED 19 DINNER QTY': '',
    'MED 19 BEDTIME QTY': '',
    'MED 19 OTHER QTY': '',
    'ADHERENCE YES 19': '',
    'ADHERENCE NO 19': '',
    'PATIENT COMMENTS.18': '',
    'COMMENTS MED 1.18': '',
    'RX?19': '',
    'OTC?19': '',
    'NHP?19': '',
    'MED 19 PHARMACIST NOTES': '',
    'MED 20': '',
    'INSTRUCITONS 1.19': '',
    'MED 20 STRENGTH AND FORM': '',
    'MED 20 AM QTY': '',
    'MED 20 LUNCH QTY': '',
    'MED 20 DINNER QTY': '',
    'MED 20 BEDTIME QTY': '',
    'MED 20 OTHER QTY': '',
    'ADHERENCE YES 20': '',
    'ADHERENCE NO 20': '',
    'PATIENT COMMENTS.19': '',
    'COMMENTS MED 1.19': '',
    'RX?20': '',
    'OTC?20': '',
    'NHP?20': '',
    'MED 20 PHARMACIST NOTES': '',
    'ADDITIONAL MEDICATION COMMENTS': '',
    'SUMMARY OF DISCUSSION 1': '',
    'SUMMARY OF DISCUSSION 2': '',
    'SUMMARY OF DISCUSSION 3': '',
    'PATIENT GOALS NOTES': '',
    'WHAT I WILL DO TO GET THERE NOTES': '',
    'LIST OF RESOURCES AND CONTACTS PROVIDED': '',
    'SUMMARY AND GOALS NOTES': '',
    'CLINICALLY RELEVANT DISCONTINUED MEDICATIONS': '',
    'THERAPEUTIC ISSUE': '',
    'THERAPEUTIC ISSUE SUGGESTED THERAPY': '',
    'THERAPEUTIC ISSUE ACTION TAKEN': '',
    'THERAPEUTIC ISSUES NOTES': '',
    'NO FOLLOW UP ISSUES': '',
    'FOLLOW UP ISSUES': '',
    'NOTIFICATION ISSUES': '',
    'ASKED ABOUT RX MEDICATIONS': '',
    'MEDS REMOVED FROM HOME': '',
    'REMOVED FROM HOME': '',
    'ASKED ABOUT OTC PRODUCTS': '',
    'ASKED ABOUT HERBAL PRODUCTS': '',
    'ASKED ABOUT UNCOMMON DOSAGE FORMS': '',
    'ASKED ABOUT ANTIOBITOCS IN THE LAST 3 MONTHS': '',
    'REFERECNED NOTES': '',
    'CIRCLE OF CARE': '',
    'ANTICIPATED DATE OF COMPLETION': '',
    'CLINICALLY RELVANT INFORMATION DOCUMNETED': '',
    'COMPLETENESS OTHER': '',
    'COMPLETENESS OTHER NOTES': '',
    'CONSULTED PHARMACY PROFILE': '',
    'CONSULTED PHYSICIAN \/ NURSE PRACTITIONER': '',
    'CONSULTED PATIENT': '',
    'CONSULTED CAREGIVER \/ AGENT': '',
    'CONSULTED ANOTHER PHARMACY': '',
    'CONSULTED MEDICATION PACKAGES': '',
    'CONSULTED LABORATORY TEST VALUES': '',
    'CONSULTED ELECTRONIC HEALTH RECROD': '',
    'CONSULTED HOSPITAL \/ OTHER FACILITY': '',
    'CONSULTED OTHER': '',
    'SOURCES OTHER NOTES': '',
    'DATE': '',
    'MEDSCHECK ANNUAL': '',
    'MEDSCHECK FOLLOW-UP': '',
    'MEDSCHECK FOR DIABETES ANNUAL': '',
    'DIABETES EDUCATION FOLLOW-UP': '',
    'MEDSCHECK AT HOME': '',
    'APPOINTMENT TIME': '',
    'COMMENTS 1': '',
    'LOCATION': '',
    'MEDSCHECK SERVICE PROVIDED': '',
    'MEDSCHECK AT PHARMACY': '',
    'MEDSCHECK AT PATIENT HOME': '',
    'NEW PATIENT TO PHARMACY': '',
    'ANNUAL REVIEW': '',
    'PATIENT REQUEST': '',
    'PHYSICAIN REFERRAL': '',
    'MEDICATION CHANGES': '',
    'CLINICAL NEED FOR SERVICE COMMENTS': '',
    '3 OR MORE MEDICATIONS': '',
    'MULTIPLE CONDITIONS': '',
    'ONE OR MORE NON RX MEDS': '',
    'ONE OR MORE NHP': '',
    'UNDADDRESSED SYMPTOMS': '',
    'POTENTIAL DRPS': '',
    'MULTIPLE DOCTORS': '',
    'ISSUES EARLY OR LATE REFILLS': '',
    'NON-ADHERENCE': '',
    'PATIENT CONFUSED': '',
    'MEDS WITH LAB MONITORING': '',
    'ABNORMAL LAB RESULTS': '',
    'PLANNED ADMISSION': '',
    'DISCHARGE FROM HOSPITAL TO COMMUNITY': '',
    'COMPLIANCE PACKAGING': '',
    'RENAL FUNCTION': '',
    'LIVER FUNCTION': '',
    'PATIENT CHARACTERISTICS OTHER': '',
    'PATIENT CHARACTERISTICS OTHER NOTE': '',
    '# OF PAGES': '',
    'PHARMACY NAME': '',
    'PHARMACY UNIT NUMBER': '',
    'PHARMACY STREET NUMBER': '',
    'PHARMACY STREET NAME': '',
    'PHARMACY PO BOX': '',
    'PHARMACY CITY': '',
    'PHARMACY PROVINCE': '',
    'PHARMACY POSTAL CODE': '',
    'PHARMACY TELEPHONE NUMBER': '',
    'PHARMACY FAX NUMBER': '',
    'PHARMACY EMAIL ADDRESS': '',
    'MEDSCHECK PHARMACIST NAME': '',
    'OCP #': '',
    'INSTRUCITONS 1.10': '',
    'COMMENTS MED 1.0': '',
    'PATIENT COMMENTS.0': '',
    'ADHERENCE YES 9': '',
    'ADHERENCE NO 9': '',
    'ADHERENCE YES 10': '',
    'ADHERENCE NO 10': '',
    'MED NOTES': '',
    'REFERRAL ENDO': '',
    'REFERRAL NURSE': '',
    'REFERRAL DIETITIAN': '',
    'REFERRAL PRIMARY CARE': '',
    'REFERRAL PHARMACIST': '',
    'REFERRAL  OTHER': '',
    'SUMMARY PATIENT GOALS 11': '',
    'WHAT I WILL DO TO GET THERE261': '',
    'RESOURCES AND CONTACTS PROVIDED4': '',
    'SUMMARY PATIENT GOALS 12': '',
    'SUMMARY PATIENT GOALS 13': '',
    'WHAT I WILL DO TO GET THERE262': '',
    'WHAT I WILL DO TO GET THERE263': '',
    'RESOURCES AND CONTACTS PROVIDED2': '',
    'RESOURCES AND CONTACTS PROVIDED3': '',
    'INDICATIONS 1': '',
    'INDICATIONS 2': '',
    'INDICATIONS 3': '',
    'INDICATIONS 4': '',
    'INDICATIONS 5': '',
    'INDICATIONS 6': '',
    'INDICATIONS 7': '',
    'INDICATIONS 8': '',
    'INDICATIONS 9': '',
    'INDICATIONS 10': '',
    'INDICATIONS 11': '',
    'INDICATIONS 12': '',
    'INDICATIONS 13': '',
    'INDICATIONS 14': '',
    'INDICATIONS 15': '',
    'INDICATIONS 16': '',
    'INDICATIONS 17': '',
    'INDICATIONS 18': '',
    'INDICATIONS 19': '',
    'INDICATIONS 20': ''
};